import { saveAs } from 'file-saver';


export const fileDownload = (data, name) => {
  saveAs(data, name);
};


export const validName = (name) => {
  let splitExtension;
  if (name) {
    splitExtension = name.split('.');
  }
  if (splitExtension.length > 2) {
    return 'extra period';
  }
  if (name.includes('&') || name.includes('@') || name.includes('#') || name.includes('$') || name.includes('!') || name.includes('%') || name.includes('*')) {
    return 'special characters';
  }
  if (name.includes('(') && name.includes(')')) {
    let removeParen = name.trim().replace(/ *\([^)]*\) */g, '');
    if (removeParen.includes(' ')) {
      removeParen = removeParen.replace(/\s/g, '_');
      return [removeParen, 'RemoveParen'];
    }
    return [removeParen, 'RemoveParen'];
  }
  if (name.indexOf(' ') > 0) {
    const joined = name.trim().split(' ').join('_');
    return [joined, 'Joined'];
  }
  return '';
};
