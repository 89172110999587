import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';

import { applyMiddleware, compose, createStore } from 'redux';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import { grid } from '@helix-re/styles';

import { message } from 'antd';
import LogRocket from 'logrocket';
import 'i18n';

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import 'antd/es/style';
// Import specific Ant Design field styles. Apart from text field, we need to
// import each field style individually, otherwise they would have no styles.
import 'antd/lib/date-picker/style';
import 'antd/lib/input-number/style';
import 'antd/lib/radio/style';

import App from 'components/App';
import ErrorBoundary from 'components/App/ErrorBoundary';

import { CLEAR_PROJECT } from 'actions/types';

import { initFirebase, isSentryErrorFromForgeOrPotreeViewer } from 'helpers';
import initialState from 'initialState';
import reducers from 'reducers';
import { Notifications } from 'react-push-notification';
import 'index.scss';
import signPotreeXhrRequests from './middleware/signPotreeXhrRequests';


const environment = process.env.BUILD_ENV;

// Disable Sentry during development.
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    beforeSend(event) {
      return isSentryErrorFromForgeOrPotreeViewer(event)
        ? null
        : event;
    },
    debug: false,
    dsn: process.env.SENTRY_DSN, // This is defined in CI during the build.
    environment,
    release: process.env.BUILD_VERSION,
  });
}

message.config({
  getContainer: () => document.body,
  maxCount: 3,
  top: grid['grid-7'],
});

let composeEnhancers = compose;
// eslint-disable-next-line no-unused-vars
let StrictMode = React.Fragment;
if (environment === 'development') {
  // eslint-disable-next-line no-underscore-dangle
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  // Monkey patch React to receive updates about unnecessary renders affecting
  // app performance.
  // See https://github.com/maicki/why-did-you-update
  // eslint-disable-next-line global-require, import/no-extraneous-dependencies
  const { whyDidYouUpdate } = require('why-did-you-update');
  whyDidYouUpdate(React, {
    collapseComponentGroups: true,
    groupByComponent: true,
    include: [/comment-this-to-enable-logging/g],
  });

  StrictMode = React.Fragment;
  // We disable the strict mode by default. This is because it currently warns
  // about warnings from 3rd party libraries. To enable strict mode, uncomment
  // the line below.
  // See https://github.com/facebook/react/issues/15550
  // ({ StrictMode } = React);
}

const history = createBrowserHistory();

const store = createStore(
  (state = initialState, action) => {
    if (action.type === CLEAR_PROJECT) {
      return {
        ...state,
        constellations: initialState.constellations,
        editor: initialState.editor,
        extractions: initialState.extractions,
        mapDimensions: initialState.mapDimensions,
        members: initialState.members,
        minimapCenter: initialState.minimapCenter,
        pointClouds: initialState.pointClouds,
        project: initialState.project,
        projects: initialState.projects,
        viewers: initialState.viewers,
      };
    }
    return reducers(history)(state, action);
  },
  initialState,
  composeEnhancers(applyMiddleware(
    thunk,
    routerMiddleware(history),
    LogRocket.reduxMiddleware(),
  )),
);

initFirebase();
signPotreeXhrRequests();

ReactGA.initialize(process.env.GOOGLE_ANALYTICS_ID);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ErrorBoundary>
        <Notifications />
        <App />
      </ErrorBoundary>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);
