import update from 'immutability-helper';
import { normalize } from 'normalizr';

import {
  RECEIVE_DELETE_EXTRACTION,
  RECEIVE_EXTRACTIONS,
  RECEIVE_FILE_SIZE_ESTIMATE,
  RECEIVE_POST_EXTRACTION,
  REQUEST_FILE_SIZE_ESTIMATE,
} from 'actions/types';
import initialState from 'initialState';
import { extractionSchema } from 'schemas';


export default (state = initialState.extractions, action) => {
  switch (action.type) {
    case RECEIVE_EXTRACTIONS:
      return update(state, {
        items: {
          $set: normalize(action.payload, [extractionSchema]),
        },
      });
    case RECEIVE_DELETE_EXTRACTION:
      return update(state, {
        items: {
          result: {
            $splice: [[state.items.result.indexOf(action.payload), 1]],
          },
        },
      });
    case RECEIVE_POST_EXTRACTION:
      /* istanbul ignore if */
      if (action.payload.progress) {
        // eslint-disable-next-line no-console
        console.log(action.payload.progress);
      } else {
        /* istanbul ignore else */
        // eslint-disable-next-line no-console
        console.log('Extraction progress status to be logged');
      }
      return update(state, {
        items: {
          entities: {
            $apply: (entities) => {
              if (entities.extractions) {
                return update(entities, {
                  extractions: {
                    $merge: {
                      [action.payload.id]: action.payload,
                    },
                  },
                });
              }
              return {
                extractions: {
                  [action.payload.id]: action.payload,
                },
              };
            },
          },
          result: {
            $push: [action.payload.id],
          },
        },
      });
    case REQUEST_FILE_SIZE_ESTIMATE:
      return update(state, {
        fileSizeEstimate: {
          $set: -1,
        },
      });
    case RECEIVE_FILE_SIZE_ESTIMATE:
      return update(state, {
        fileSizeEstimate: {
          $set: action.payload.filesize,
        },
      });
    default:
      return state;
  }
};
