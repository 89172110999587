/* global Potree */

function shouldSignUrl(url) {
  return (url.includes('.laz') || url.includes('ept-hierarchy/')) && Potree.signedUrls;
}

function signUrl(url) {
  let key;
  Object.keys(Potree.signedUrls.urls).forEach((fileUrl) => {
    if (url.indexOf(fileUrl) >= 0) {
      key = Potree.signedUrls.urls[fileUrl];
    }
  });

  const sign = `?Expires=${Potree.signedUrls.expires}&KeyName=${Potree.signedUrls.keyname}&Signature=${key}`;
  return url + sign;
}

export default function signPotreeXhrRequests() {
  const { fetch: originalFetch } = window;
  window.fetch = async (...args) => {
    // eslint-disable-next-line
    let [resource, config] = args;

    if (shouldSignUrl(resource)) {
      resource = signUrl(resource);
    }

    const response = await originalFetch(resource, config);
    return response;
  };


  const origOpen = XMLHttpRequest.prototype.open;
  XMLHttpRequest.prototype.open = function patchedOpen(method, url, ...rest) {
    // only intercept relevant Potree pointcloud file xhr requests
    if (shouldSignUrl(url)) {
      url = signUrl(url);
    }
    return origOpen.call(this, method, url, ...rest);
  };
}
