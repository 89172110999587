import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';

import { createSelectorWithArgs, isDefined } from 'helpers';
import { SCENE_TYPE } from 'constants/scene';
import {
  getEditorFilters,
  getEditorSelectedSceneIds,
  getEditorSelectedSceneIdsForSceneMenu,
} from './editor';
import { getMapDimensions } from './mapDimensions';
import {
  getLinkedSceneIdObject,
  getMappedScenesBySpaceId,
  getModelEntities,
  getProject,
  getSceneEntities,
  getScenes,
  getScenesBySpaceId,
  getSpaceEntities,
  getSpaceIds,
  getSpaceIdsBySceneId,
  getSpaces,
} from './project';
import {
  getPathModelId,
  getPathSceneId,
  getPathSpaceId,
} from './router';
import { getConstellations } from './constellations';

export const getProjectSpaceId = createSelector(
  getProject,
  getPathSpaceId,
  (project, spaceId) => project
    && project.spaces.find((space) => space.toString() === spaceId),
);

export const getPathScene = createSelector(
  getSceneEntities,
  getPathSceneId,
  (entities, id) => entities[id],
);

export const getPathSpace = createSelector(
  getSpaceEntities,
  getPathSpaceId,
  (entities, id) => entities[id],
);

export const getPathSpaceFloorPlan = createSelector(
  getPathSpace,
  (space = {}) => space.floorPlan || undefined,
);

export const getPathModel = createSelector(
  getModelEntities,
  getPathModelId,
  (entities, id) => entities[id],
);

export const getEditorSelectedScenes = createSelector(
  getSceneEntities,
  getEditorSelectedSceneIds,
  (entities, ids) => ids.map((id) => entities[id]),
);

export const getEditorBoundingBoxSceneIds = createSelector(
  getEditorSelectedScenes,
  getEditorSelectedSceneIds,
  getConstellations,
  (editorSelectedScenes, editorSelectedSceneIds, constellations) => constellations.reduce((acc, constellation) => {
    if (!editorSelectedScenes.some((x) => x.constellationId === constellation.id)) return acc;
    const constellationSceneIds = constellation.scenes.map((x) => x.id).filter((x) => !acc.includes(x));
    return [
      ...acc,
      ...constellationSceneIds,
    ];
  }, editorSelectedSceneIds),
);

export const filterScene = (scene, spaceScenes, linkedSceneIdObject, filters) => {
  if (filters.type === SCENE_TYPE.SCENE_2D && scene.sceneType !== SCENE_TYPE.SCENE_2D) {
    return false;
  }
  if (filters.type === SCENE_TYPE.SCENE_360 && scene.sceneType !== SCENE_TYPE.SCENE_360) {
    return false;
  }

  if (filters.mapped === false && spaceScenes.some((spaceScene) => spaceScene.mapX !== undefined && spaceScene.mapY !== undefined)) {
    return false;
  }

  if (filters.mapped === true && spaceScenes.every((spaceScene) => spaceScene.mapX === undefined || spaceScene.mapY === undefined)) {
    return false;
  }
  if (filters.linked === false && linkedSceneIdObject[scene.id]) {
    return false;
  }
  if (filters.linked === true && !linkedSceneIdObject[scene.id]) {
    return false;
  }
  if (filters.sensitive === false && scene.sensitive) {
    return false;
  }
  if (filters.sensitive === true && !scene.sensitive) {
    return false;
  }
  if (filters.cleared === false && scene.cleared) {
    return false;
  }
  if (filters.cleared === true && !scene.cleared) {
    return false;
  }
  if (filters.redacted === false && scene.redacted) {
    return false;
  }
  if (filters.redacted === true && !scene.redacted) {
    return false;
  }
  if (filters.orientation === true && !('angle' in scene)) {
    return false;
  }
  if (filters.orientation === false && ('angle' in scene)) {
    return false;
  }

  if (filters.inventoryFound === true && !scene.inventoryFound) {
    return false;
  }

  if (filters.inventoryFound === false && scene.inventoryFound) {
    return false;
  }
  return true;
};

export const filterSceneMinimap = (scene, spaceScenes, linkedSceneIdObject, filters) => {
  if (filters.type === SCENE_TYPE.SCENE_2D && scene.sceneType !== SCENE_TYPE.SCENE_2D) {
    return false;
  }
  if (filters.type === SCENE_TYPE.SCENE_360 && scene.sceneType !== SCENE_TYPE.SCENE_360) {
    return false;
  }

  if (filters.linked === false && linkedSceneIdObject[scene.id]) {
    return false;
  }
  if (filters.linked === true && !linkedSceneIdObject[scene.id]) {
    return false;
  }
  if (filters.sensitive === false && scene.sensitive) {
    return false;
  }
  if (filters.sensitive === true && !scene.sensitive) {
    return false;
  }
  if (filters.cleared === false && scene.cleared) {
    return false;
  }
  if (filters.cleared === true && !scene.cleared) {
    return false;
  }
  if (filters.redacted === false && scene.redacted) {
    return false;
  }
  if (filters.redacted === true && !scene.redacted) {
    return false;
  }
  if (filters.orientation === true && !('angle' in scene)) {
    return false;
  }
  if (filters.orientation === false && ('angle' in scene)) {
    return false;
  }

  if (filters.inventoryFound === true && !scene.inventoryFound) {
    return false;
  }

  if (filters.inventoryFound === false && scene.inventoryFound) {
    return false;
  }

  return true;
};


export const getEditorFilteredScenes = createSelector(
  getScenes,
  getSpaceIdsBySceneId,
  getSpaceEntities,
  getLinkedSceneIdObject,
  getEditorFilters,
  (scenes, spaceIdsBySceneId, spaceEntities, linkedSceneIdObject, filters) => scenes.filter((scene) => {
    const spaceScenes = spaceIdsBySceneId[scene.id].map((spaceId) => spaceEntities[spaceId].scenes[scene.id]);
    return filterScene(scene, spaceScenes, linkedSceneIdObject, filters);
  }),
);

export const getEditorFilteredScenesMinimap = createSelector(
  getScenes,
  getSpaceIdsBySceneId,
  getSpaceEntities,
  getLinkedSceneIdObject,
  getEditorFilters,
  (scenes, spaceIdsBySceneId, spaceEntities, linkedSceneIdObject, filters) => scenes.filter((scene) => {
    const spaceScenes = spaceIdsBySceneId[scene.id].map((spaceId) => spaceEntities[spaceId].scenes[scene.id]);
    return filterSceneMinimap(scene, spaceScenes, linkedSceneIdObject, filters);
  }),
);

export const getEditorFilteredScenesBySpaceIdMinimap = createSelector(
  getScenesBySpaceId,
  getSpaceEntities,
  getSpaceIds,
  getLinkedSceneIdObject,
  getEditorFilters,
  (scenesBySpaceId, spaceEntities, spaceIds, linkedSceneIdObject, filters) => spaceIds.reduce((acc, spaceId) => ({
    ...acc,
    [spaceId]: scenesBySpaceId[spaceId].filter((scene) => {
      const spaceScene = spaceEntities[spaceId].scenes[scene.id];
      return spaceScene && isDefined(spaceScene.mapX) && isDefined(spaceScene.mapY) && filterSceneMinimap(scene, [spaceScene], linkedSceneIdObject, filters);
    }, {}),
  }), {}),
);

const filterScenesIntoSpaces = (scenesBySpaceId, spaceIds, spaceEntities, linkedSceneIdObject, filters) => spaceIds.reduce((acc, id) => {
  const scenes = scenesBySpaceId[id].filter((scene) => {
    const spaceScene = spaceEntities[id].scenes[scene.id];
    return filterScene(scene, [spaceScene], linkedSceneIdObject, filters);
  }, {});
  if (scenes.length) {
    acc.push({
      scenes,
      space: spaceEntities[id],
    });
  }
  return acc;
}, []);

export const getEditorSensitiveSpaces = createSelector(
  getScenesBySpaceId,
  getSpaceIds,
  getSpaceEntities,
  getLinkedSceneIdObject,
  (scenesBySpaceId, spaceIds, spaceEntities, linkedSceneIdObject) => filterScenesIntoSpaces(scenesBySpaceId, spaceIds, spaceEntities, linkedSceneIdObject, { cleared: false, redacted: false, sensitive: true }),
);

export const getEditorUnclearedSpaces = createSelector(
  getScenesBySpaceId,
  getSpaceIds,
  getSpaceEntities,
  getLinkedSceneIdObject,
  (scenesBySpaceId, spaceIds, spaceEntities, linkedSceneIdObject) => filterScenesIntoSpaces(scenesBySpaceId, spaceIds, spaceEntities, linkedSceneIdObject, { cleared: false }),
);

export const getEditorUnmappedSpaces = createSelector(
  getScenesBySpaceId,
  getSpaceIds,
  getSpaceEntities,
  getLinkedSceneIdObject,
  (scenesBySpaceId, spaceIds, spaceEntities, linkedSceneIdObject) => filterScenesIntoSpaces(scenesBySpaceId, spaceIds, spaceEntities, linkedSceneIdObject, { mapped: false }),
);

export const getEditorUnlinkedSpaces = createSelector(
  getScenesBySpaceId,
  getSpaceIds,
  getSpaceEntities,
  getLinkedSceneIdObject,
  (scenesBySpaceId, spaceIds, spaceEntities, linkedSceneIdObject) => filterScenesIntoSpaces(scenesBySpaceId, spaceIds, spaceEntities, linkedSceneIdObject, { linked: false }),
);

export const getEditorFilteredScenesBySpaceId = createSelector(
  getScenesBySpaceId,
  getSpaceEntities,
  getSpaceIds,
  getLinkedSceneIdObject,
  getEditorFilters,
  (scenesBySpaceId, spaceEntities, spaceIds, linkedSceneIdObject, filters) => spaceIds.reduce((acc, spaceId) => ({
    ...acc,
    [spaceId]: scenesBySpaceId[spaceId].filter((scene) => {
      const spaceScene = spaceEntities[spaceId].scenes[scene.id];
      return filterScene(scene, [spaceScene], linkedSceneIdObject, filters);
    }, {}),
  }), {}),
);

export const getMappedEditorSelectedSpaceScenes = createSelectorWithArgs(
  [getEditorSelectedSceneIds, getSpaceEntities, 1],
  (ids, spaceEntities, spaceId) => ids.reduce((acc, id) => {
    const spaceScene = spaceEntities[spaceId].scenes[id];
    if (spaceScene && spaceScene.mapX !== undefined && spaceScene.mapY !== undefined) {
      acc.push(spaceScene);
    }
    return acc;
  }, []),
);

export const getBoundingBox = createSelector(
  getPathSpace,
  getConstellations,
  getEditorSelectedScenes,
  getMappedEditorSelectedSpaceScenes,
  (space, constellations, selectedScenes, spaceScenes) => {
    // Loop through selected scenes
    const selectedSpaceScenes = selectedScenes.reduce((acc, selectedScene) => {
      // Check if scene is a part of constelation
      if (selectedScene.constellationId) {
        // Find the constellation and return its scenes
        const constellation = constellations.find((x) => x.id === selectedScene.constellationId);

        // Did not found constellation
        if (!constellation) return acc;

        const selectedSceneIds = selectedScenes.map((x) => x.id);
        const spaceScenesToAdd = constellation.scenes.filter((scene) => !selectedSceneIds.includes(scene.id));
        // Get only mapped space scenes
        const spaceSceneIds = spaceScenesToAdd.map((x) => space.scenes[x.id]).filter((x) => !!x);

        return [
          ...acc,
          ...spaceSceneIds,
        ];
      }
      return acc;
    }, spaceScenes);

    // Get only space scenes that are placed on minimap
    const selectedMappedSpaceScenes = selectedSpaceScenes.filter((spaceScene) => typeof spaceScene.mapX === 'number' && typeof spaceScene.mapY === 'number');
    // Do not compute bounding box if there are less than 2 scenes selected
    if (selectedMappedSpaceScenes.length < 2) {
      return undefined;
    }
    // Calculate lowest and highest marker positions
    const minMax = selectedMappedSpaceScenes.reduce((acc, spaceScene) => {
      if (acc.minX === undefined) {
        acc.minX = spaceScene.mapX;
        acc.minY = spaceScene.mapY;
        acc.maxX = spaceScene.mapX;
        acc.maxY = spaceScene.mapY;
      } else {
        acc.minX = Math.min(acc.minX, spaceScene.mapX);
        acc.minY = Math.min(acc.minY, spaceScene.mapY);
        acc.maxX = Math.max(acc.maxX, spaceScene.mapX);
        acc.maxY = Math.max(acc.maxY, spaceScene.mapY);
      }
      return acc;
    }, {});

    return {
      height: minMax.maxY - minMax.minY,
      width: minMax.maxX - minMax.minX,
      x: minMax.minX,
      y: minMax.minY,
    };
  },
);

export const areScenesPlaceable = createCachedSelector(
  getSpaceEntities,
  getEditorSelectedSceneIdsForSceneMenu,
  getPathSpace,
  (spaceEntities, ids, pathSpace) => !!(pathSpace && pathSpace.mapAsset && ids.some((id) => {
    const scene = spaceEntities[pathSpace.id].scenes[id];
    if (scene) {
      return [scene.mapX, scene.mapY].some((x) => !isDefined(x));
    }
    return false;
  })),
)((state, sceneId) => `${state.router.location.pathname}/${sceneId}`);

export const getMapExtremesBySpaceId = createSelector(
  getSpaces,
  getMappedScenesBySpaceId,
  getMapDimensions,
  (spaces, mappedScenesBySpaceId, mapDimensions) => spaces.reduce((acc, space) => {
    const mapHeight = mapDimensions[space.id] ? mapDimensions[space.id].height : 0;
    const mapWidth = mapDimensions[space.id] ? mapDimensions[space.id].width : 0;
    acc[space.id] = mappedScenesBySpaceId[space.id].reduce((spaceAcc, scene) => {
      const { mapX, mapY } = space.scenes[scene.id];
      return {
        maxX: Math.max(spaceAcc.maxX, mapX),
        maxY: Math.max(spaceAcc.maxY, mapHeight - mapY),
        minX: Math.min(spaceAcc.minX, mapX),
        minY: Math.min(spaceAcc.minY, mapHeight - mapY),
      };
    }, {
      maxX: mapWidth,
      maxY: mapHeight,
      minX: 0,
      minY: 0,
    });

    return acc;
  }, {}),
);
