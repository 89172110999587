import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import addNotification from 'react-push-notification';
import { fetchAttachments, removeAttachment } from 'actions/attachments';
import { fetchEditorProject } from 'actions/project';
import { PropTypes } from 'constants';
import {
  getNotificationFromAttachment,
  isAttachmentComplete,
  isAttachmentFailed,
  useMediaQueryIsMobile,
} from 'helpers';
import { getAttachments } from 'selectors/attachments';
import { getPathProjectId } from 'selectors/router';

import Body1 from 'components/UI/Typography/Body1';
import IconCheck from 'assets/svg/IconCheck';
import IconDelete from 'assets/svg/IconDelete';
import IconNotifications from 'assets/svg/IconNotifications';
import Label from 'components/UI/Typography/Label';
import Popover from 'components/UI/Popover';
import Tooltip from 'components/UI/Tooltip';

import s from './index.scss';

const Notifications = (props) => {
  const { t } = useTranslation();
  const [needRefetch, setNeedRefetch] = useState(0);
  const isMobile = useMediaQueryIsMobile();

  const total = useMemo(
    () => props.attachments.reduce((acc, attachment) => (isAttachmentComplete(attachment) ? acc : acc + 1), 0),
    [props.attachments],
  );

  useEffect(() => {
    if (needRefetch > 0 && !total) {
      props.fetchEditorProject(props.projectId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  useEffect(() => {
    setNeedRefetch(total);
  }, [total]);


  useEffect(() => {
    props.fetchAttachments(props.projectId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCancel = (attachment) => {
    if (window.dataProcessing !== undefined) {
      const pos = (window.dataProcessing.indexOf(attachment.id));
      if (pos >= 0) {
        window.dataProcessing.splice(pos, 1);
      }
    }
    if (window.uploads[attachment.id]) {
      window.uploads[attachment.id].cancel();
    }
    if (props.onCancel) {
      return props.onCancel(attachment);
    }
    if (window.data !== undefined) {
      const pos = (window.data.indexOf(attachment.id));
      if (pos >= 0) {
        window.data.splice(pos, 1);
      }
    }
    return props.removeAttachment(attachment.id);
  };

  return (
    <Popover
      align={{
        offset: [-4, 0],
      }}
      arrow={!isMobile}
      content={(
        <>
          {!total && (
            <div className={s.empty}>
              <IconCheck className={classNames(s.iconItem, s.emptyIcon)} />
              <Body1>
                {t('editor.notifications.empty')}
              </Body1>
            </div>
          )}
          {total === 0 && window.dataProcessing !== undefined && window.dataProcessing.length > 0 && props.attachments.map((attachment) => {
            if (isAttachmentComplete(attachment)) {
              if (window.dataProcessing.indexOf(attachment.id) >= 0) {
                addNotification({
                  duration: 10000,
                  message: attachment.cardTitle + ': Upload and processing completed', // eslint-disable-line prefer-template
                  native: true,
                  subtitle: attachment.cardTitle + 'completed', // eslint-disable-line prefer-template
                  theme: 'darkblue',
                  title: 'Density, Inc',
                });
                const pos = (window.dataProcessing.indexOf(attachment.id));
                window.dataProcessing.splice(pos, 1);
              }
            }
            return null;
          })}
          {total > 0 && props.attachments.map((attachment) => {
            if (window.dataProcessing === undefined) {
              window.dataProcessing = [];
            }
            if (isAttachmentComplete(attachment)) {
              if (window.dataProcessing.indexOf(attachment.id) >= 0) {
                addNotification({
                  duration: 10000,
                  message: attachment.cardTitle + ': Upload and processing completed.', // eslint-disable-line prefer-template
                  native: true, // when using native, your OS will handle theming.
                  subtitle: attachment.cardTitle + 'Completed', // eslint-disable-line prefer-template
                  theme: 'darkblue',
                  title: 'Density, Inc',
                });
                const pos = (window.dataProcessing.indexOf(attachment.id));
                window.dataProcessing.splice(pos, 1);
              }
              return null;
            }
            const notification = getNotificationFromAttachment(attachment);
            return (
              <div
                className={s.item}
                key={attachment.id}
              >
                <div
                  className={classNames(s.iconItem, {
                    [s.iconError]: isAttachmentFailed(attachment),
                  })}
                >
                  <notification.Icon {...notification.iconProps} size={18} />
                </div>
                <span className={s.label}>
                  {t(notification.statusi18nKey, {
                    name: attachment.cardTitle,
                  })}
                </span>
                <Tooltip
                  placement="bottom"
                  title={t(notification.canceli18nKey)}
                >
                  <button
                    className={s.deleteButton}
                    onClick={() => onCancel(attachment)}
                    type="button"
                  >
                    <IconDelete size={18} />
                  </button>
                </Tooltip>
              </div>
            );
          })}
        </>
      )}
      overlayClassName={s.popover}
      placement={isMobile
        ? 'bottom'
        : 'bottomLeft'}
    >
      <button
        className={s.button}
        type="button"
      >
        <IconNotifications />
        {total > 0 && (
          <div className={s.badge}>
            <Label>
              {String(total)}
            </Label>
          </div>
        )}
      </button>
    </Popover>
  );
};

Notifications.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.Attachment).isRequired,
  fetchAttachments: PropTypes.func.isRequired,
  fetchEditorProject: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  projectId: PropTypes.string.isRequired,
  removeAttachment: PropTypes.func.isRequired,
};

export const PureTestComponent = Notifications;

const mapStateToProps = (state) => ({
  attachments: getAttachments(state),
  projectId: getPathProjectId(state),
});

const mapDispatchToProps = {
  fetchAttachments,
  fetchEditorProject,
  removeAttachment,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Notifications);
