import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import useMobileDetect from 'use-mobile-detect-hook';
import Publish from 'components/Editor/Publish';


import { PropTypes, ROUTES, ROUTE_WEBSITE_SUPPORT } from 'constants';
import {
  editorUrlToViewerUrl,
  isDemoAccount,
  isMemberEditor,
} from 'helpers';

import Button from 'components/UI/Button';
import Popover from 'components/UI/Popover';

import s from './index.scss';

export default function UserMenuLayout(props) {
  const { t } = useTranslation();

  const { isMobile } = useMobileDetect();

  const mobile = isMobile();

  const isViewingProject = !!props.projectId;

  return (
    <Popover
      align={{
        overflow: {
          adjustX: 0,
          adjustY: 0,
        },
        points: ['tr', 'br'],
      }}
      buttonClassName={s.button}
      menu={[
        {
          content: (
            <div className={s.userMeta}>
              <div className={classNames(s.userMetaHeader)}>
                {t('menu.user')}
                :
              </div>
              <div className={s.userMetaEmail}>
                {isDemoAccount(props.user)
                  ? t('menu.demoAccountEmail')
                  : props.user.email}
              </div>
            </div>
          ),
        },
        props.editing && {
          content: (
            <Button
              newTab
              simple
              to={editorUrlToViewerUrl(props.projectId)}
            >
              {t('menu.openViewer')}
            </Button>
          ),
        },
        props.editing && mobile && {
          content: (
            <Publish
              simple
            />
          ),
        },
        (props.isGlobeView || (!props.editing && isMemberEditor(props.user))) && {
          content: (
            <Button
              newTab
              simple
              to={props.isGlobeView
                ? ROUTES.editor.root()
                : ROUTES.editor.project(props.projectId).root()}
            >
              {t('menu.openEditor')}
            </Button>
          ),
        },
        props.editing && !mobile && {
          content: (
            <Button
              onClick={props.onClickKeyboardShortcuts}
              simple
            >
              {t('menu.shortcuts')}
            </Button>
          ),
        },
        !props.editing && !props.isGlobeView && isViewingProject && {
          content: (
            <Button
              onClick={props.onClickVideoGuide}
              simple
            >
              {t('menu.guide')}
            </Button>
          ),
        },
        props.isGlobeView && {
          content: (
            <Button
              simple
              to={ROUTE_WEBSITE_SUPPORT}
            >
              {t('menu.help')}
            </Button>
          ),
        },
        // {
        //   content: (
        //     <Button
        //       onClick={props.onClickHelp}
        //       simple
        //     >
        //       {t('menu.help')}
        //     </Button>
        //   ),
        // },
        {
          content: (
            <Button
              onClick={props.onClickSignOut}
              simple
            >
              {t('menu.signOut')}
            </Button>
          ),
        },
      ].filter(Boolean)}
      onChangeVisible={props.onChangeVisible}
    />
  );
}

UserMenuLayout.propTypes = {
  editing: PropTypes.bool.isRequired,
  isGlobeView: PropTypes.bool.isRequired,
  onChangeVisible: PropTypes.func,
  // onClickHelp: PropTypes.func.isRequired,
  onClickKeyboardShortcuts: PropTypes.func.isRequired,
  onClickSignOut: PropTypes.func.isRequired,
  onClickVideoGuide: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  user: PropTypes.User.isRequired,
};
