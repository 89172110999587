import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { message, Modal } from 'antd';

import { setEditorFilters } from 'actions/editor';
import { fetchPublication, publishProject } from 'actions/project';
import { setPathSpaceId } from 'actions/router';
import { PropTypes } from 'constants';
import { CY_HEADER_PUBLISH_PUBLISHBUTTON } from 'constants/cypress';
import { isPublishInProgress, capitalize, isDefined } from 'helpers';
import {
  getEditorSensitiveSpaces,
  getEditorUnclearedSpaces,
  getEditorUnlinkedSpaces,
  getEditorUnmappedSpaces,
} from 'selectors';
import { getProject, getSpaceEntities } from 'selectors/project';
import { getPathProjectId } from 'selectors/router';

import Button from 'components/UI/Button';

import s from './index.scss';

function Publish(props) {
  const {
    editorSensitiveSpaces,
    editorUnclearedSpaces,
    editorUnlinkedSpaces,
    editorUnmappedSpaces,
    fetchPublication: actionFetchPublication,
    project,
    projectId,
    publishProject: actionPublishProject,
    setEditorFilters: actionSetEditorFilters,
    setPathSpaceId: actionSetPathSpaceId,
    ...buttonProps
  } = props;

  const { t } = useTranslation();

  const isPublishing = project
    ? isPublishInProgress(project)
    : false;

  const projectStatus = project && project.publication.status;
  const [isLoading, setIsLoading] = useState(isPublishing);

  const modal = useRef();


  const publicationStatusInterval = useRef();

  useEffect(() => {
    setIsLoading(isPublishing);
  }, [isPublishing]);

  useEffect(() => {
    if (isPublishing) {
      publicationStatusInterval.current = setInterval(() => {
        actionFetchPublication(projectId);
      }, 20000);
    }

    return () => {
      clearInterval(publicationStatusInterval.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectStatus]);

  const onClickPublish = () => {
    const doPublishProject = () => {
      setIsLoading(true);

      return actionPublishProject(projectId)
        .then(() => {
          message.success(t('editor.settings.publish.success'));
        })
        .catch(() => {
          setIsLoading(false);
          message.error(t('editor.settings.publish.error'));
        });
    };

    const onClickSpace = (spaceId, filters) => {
      actionSetEditorFilters(filters);
      actionSetPathSpaceId(spaceId);
      modal.current.destroy();
    };
    const listSpaces = (spaces) => {
      const spacesId = Object.values(spaces).filter((space) => !isDefined(space.initialScene));
      const countItems = Object.values(spacesId).length;
      return countItems > 0 && (
        <>
          <p className={s.sceneCount}>
            {t('editor.settings.publish.initialSceneWarning', { countItems })}
          </p>
          <ul>
            {Object.values(spacesId).map((space) => (
              <li key={space.name}>
                <button
                  className={s.buttonSpace}
                  onClick={() => onClickSpace(space.id, {})}
                  type="button"
                >
                  {space.name}
                </button>
              </li>
            ))}
          </ul>
        </>
      );
    };

    const listScenes = (spaces, filters, i18nKey) => {
      const count = spaces.reduce((total, space) => total + space.scenes.length, 0);

      return spaces.length > 0 && (
        <>
          <p className={s.sceneCount}>
            {t(i18nKey, { count })}
          </p>
          <ul>
            {spaces.map((space) => (
              <li key={space.space.id}>
                <button
                  className={s.buttonSpace}
                  onClick={() => onClickSpace(space.space.id, filters)}
                  type="button"
                >
                  {space.space.name}
                </button>
              </li>
            ))}
          </ul>
        </>
      );
    };

    const invalidSpacesId = Object.values(props.pathspaces).filter((space) => !isDefined(space.initialScene));
    const invalidInitialSpaces = Object.values(invalidSpacesId).length;

    if (
      editorSensitiveSpaces.length
      || editorUnclearedSpaces.length
      || editorUnmappedSpaces.length
      || editorUnlinkedSpaces.length
      || (invalidInitialSpaces > 0)
    ) {
      modal.current = Modal.confirm({
        cancelText: capitalize(t('words.cancel')),
        content: (
          <>
            <p>
              {t('editor.settings.publish.scenesWarning')}
            </p>
            { }
            {listSpaces(props.pathspaces)}
            {listScenes(editorUnclearedSpaces, {
              cleared: false,
            }, 'editor.settings.publish.warning.notClearedScenes')}
            {listScenes(editorSensitiveSpaces, {
              cleared: false,
              redacted: false,
              sensitive: true,
            }, 'editor.settings.publish.warning.sensitiveScenes')}
            {listScenes(editorUnmappedSpaces, {
              mapped: false,
            }, 'editor.settings.publish.warning.notPlacedScenes')}
            {listScenes(editorUnlinkedSpaces, {
              linked: false,
            }, 'editor.settings.publish.warning.notLinkedScenes')}
          </>
        ),
        okText: capitalize(t('words.publish')),
        onOk: doPublishProject,
        title: t('editor.settings.publish.title'),
      });
    } else {
      doPublishProject();
    }
  };

  return (
    <Button
      data-cy={CY_HEADER_PUBLISH_PUBLISHBUTTON}
      disabled={!project || isLoading}
      loading={isLoading}
      onClick={onClickPublish}
      {...buttonProps}
    >
      {isPublishing
        ? capitalize(t('words.publishing'))
        : capitalize(t('words.publish'))}
      {' '}
      Project
    </Button>
  );
}

const Spaces = PropTypes.arrayOf(PropTypes.shape({
  scenes: PropTypes.arrayOf(PropTypes.Scene),
  space: PropTypes.Space,
}));

Publish.propTypes = {
  buttonProps: PropTypes.shape(),
  editorSensitiveSpaces: Spaces.isRequired,
  editorUnclearedSpaces: Spaces.isRequired,
  editorUnlinkedSpaces: Spaces.isRequired,
  editorUnmappedSpaces: Spaces.isRequired,
  fetchPublication: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  pathspaces: PropTypes.any,
  project: PropTypes.Project,
  projectId: PropTypes.string.isRequired,
  publishProject: PropTypes.func.isRequired,
  setEditorFilters: PropTypes.func.isRequired,
  setPathSpaceId: PropTypes.func.isRequired,
};

export const PureTestComponent = Publish;

const mapStateToProps = (state) => ({
  editorSensitiveSpaces: getEditorSensitiveSpaces(state),
  editorUnclearedSpaces: getEditorUnclearedSpaces(state),
  editorUnlinkedSpaces: getEditorUnlinkedSpaces(state),
  editorUnmappedSpaces: getEditorUnmappedSpaces(state),
  pathspaces: getSpaceEntities(state),
  project: getProject(state),
  projectId: getPathProjectId(state),
});

const mapDispatchToProps = {
  fetchPublication,
  publishProject,
  setEditorFilters,
  setPathSpaceId,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Publish);
